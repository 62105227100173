<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Status Page</h4>
    <p>View our status page powered by uptime.com</p>
    <CButton @click="openURL('https://status.robocalls.ai')" color="info" class="card-box-button">
      Status Page
    </CButton>
  </div>


</div>
</template>

<script>
export default {
  name: 'Status-Page',
  methods: {
    //--------------------------------------------------------------------------
    openURL(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
